import React from 'react'
import { useUrl,useLocale, useSiteQuery } from '../hooks'
import { useLocation } from '@reach/router'
import { mapFetchifyCode } from '../utils/mapping'

const Schema = ({
    type = null,
    title = null,
    url = null,
    desc = null,
    created = null,
    image = null,
    updated = null,
    link = null,
    slug,
    name= null,
    description = null,
    recipeImg = null
}) => {
    const siteUrl = 'https://www.lipton.com';
    const { href } = useLocation()
    const locale = useLocale()

    const { logo } = useSiteQuery()
    const loc = useUrl()
    if (type === "Article") {
        return (
            <div>
                <div style={{ display: "none" }}>
                    <div itemScope={true} itemType="http://schema.org/Article">
                        <span itemProp='name'>{title}</span>
                        <span itemProp='author'>Lipton</span>
                        <span itemProp='headline'>{title}</span>
                        <span itemProp='url'>{`${siteUrl}${slug}`}</span>
                        <span itemProp='description'>{desc}</span>
                        {/* <span itemProp='datePublished'>{created}</span> */}
                        <span itemProp='image' itemScope={true} itemType="http://schema.org/ImageObject">
                            <span itemProp='url'>{image}</span>
                            <span itemProp='contentUrl'>{image}</span>
                            <span itemProp='name'>{title}</span>
                            <span itemProp='caption'>{title}</span>
                            <span itemProp='width'>800</span>
                            <span itemProp='height'>800</span>
                        </span>
                        <span itemProp='publisher' itemScope={true} itemType="http://schema.org/Organization">
                            {/* <span itemProp='headline'>lipton</span> */}
                            {/* <span itemProp='dateModified'>{updated}</span> */}
                            <span itemProp='mainEntityOfPage'>{url}</span>
                            <div itemProp='logo' itemScope itemType='http://schema.org/ImageObject'>
                                <meta itemProp='url' content={logo.image[0].fluid.src} />
                                <meta itemProp='width' content='100' />
                                <meta itemProp='height' content='70' />
                            </div>
                            {/* <span itemProp='author'>Lipton Author</span> */}
                            {/* <span itemProp='articleSection'>{title}</span> */}
                        </span>
                    </div>
                </div>
            </div>
        )
    } 
    // else if (type === "Product") {
    //     return (
    //         <div>
    //             <div style={{ display: "none" }}>
    //                 <div itemScope={true} itemType="http://schema.org/Product">
    //                     <span itemProp='name'>{title}</span>
    //                     <span itemProp='url'>{`${siteUrl}${slug}`}</span>
    //                     <span itemProp='description'>{desc}</span>
    //                     {/* <span itemProp='datePublished'>{created}</span> */}
    //                     <span itemProp='image' itemScope={true} itemType="http://schema.org/ImageObject">
    //                         <span itemProp='url'>{image}</span>
    //                         <span itemProp='contentUrl'>{image}</span>
    //                         <span itemProp='name'>{title}</span>
    //                         <span itemProp='caption'>{title}</span>
    //                         <span itemProp='width'>800</span>
    //                         <span itemProp='height'>800</span>
    //                     </span>
    //                     <span itemProp='offers' itemScope={true} itemType="https://schema.org/Offer">
    //                         {/* <span itemProp='price'>10</span> */}
    //                         <span itemProp='priceCurrency'>{currencyCode}</span>
    //                         <span itemProp='priceValidUntil'>{date}</span>
    //                         <span itemProp='itemCondition'>https://schema.org/NewCondition</span>
    //                         <span itemProp='availability'>InStock</span>
    //                         <span itemProp='url'>{link}</span>
    //                     </span>
    //                     {/* <span itemProp='publisher' itemScope={true} itemType="http://schema.org/Organization"> */}
    //                         {/* <span itemProp='headline'>lipton</span> */}
    //                         {/* <span itemProp='dateModified'>{updated}</span> */}
    //                         <span itemProp='mainEntityOfPage'>{url}</span>
    //                         <div itemProp='logo' itemScope itemType='http://schema.org/ImageObject'>
    //                             <meta itemProp='url' content={logo.image[0].fluid.src} />
    //                             <meta itemProp='width' content='100' />
    //                             <meta itemProp='height' content='70' />
    //                         </div>
    //                         {/* <span itemProp='author'>Lipton Author</span> */}
    //                         {/* <span itemProp='articleSection'>{title}</span> */}
    //                     {/* </span> */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // } 
    else if (type === "Recipe") {
        return (
            <div>
                <div style={{ display: "none" }}>
                    {/* <span itemProp='publisher' itemScope={true} itemType="http://schema.org/Recipe">
                        <span itemProp='brand'>Lipton</span>
                        <span itemProp='url'>{`${siteUrl}/${loc}`}</span>
                        <span itemProp='mainEntityOfPage'>{href}</span>     
                        <meta itemProp='logo' content={logo.image[0].fluid.src} />
                    </span> */}

                    <span itemScope={true} itemType="http://schema.org/Recipe">
                        <span itemProp="name">{name}</span>
                        <span itemProp='url'>{`${siteUrl}/${loc}/`}</span>
                        <span itemProp='mainEntityOfPage'>{href}</span>     
                        {/* <meta itemProp='logo' content={logo.image[0].fluid.src} /> */}
                        <span itemProp="description">{description}</span>
                        <span itemProp='image' itemScope={true} itemType="http://schema.org/ImageObject">
                            <span itemProp='url'>{recipeImg}</span>
                            <span itemProp='contentUrl'>{recipeImg}</span>
                            <span itemProp='name'>{name}</span>
                            <span itemProp='caption'>{name}</span>
                            <span itemProp='width'>800</span>
                            <span itemProp='height'>800</span>
                        </span>
                    </span>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div style={{ display: "none" }}>
                    <span itemProp='publisher' itemScope={true} itemType="http://schema.org/Organization">
                        <span itemProp='brand'>Lipton</span>
                        <span itemProp='url'>{`${siteUrl}/${loc}/`}</span>
                        {/* <span itemProp='headline'>lipton</span> */}
                        <span itemProp='mainEntityOfPage'>{href}</span>
                        <meta itemProp='logo' content={logo.image[0].fluid.src} />
                            
                        {/* <span itemProp='author'>Lipton Author</span> */}
                    </span>
                </div>
            </div>
        )
    }
}

export default Schema