// extracted by mini-css-extract-plugin
export var Herocta = "SliderImages-module--Herocta--66e0b";
export var arrowContainerRight = "SliderImages-module--arrow-container-right--295f6";
export var bannercta = "SliderImages-module--bannercta--e7e94";
export var desktop_image = "SliderImages-module--desktop_image--12582";
export var displayNavigation = "SliderImages-module--display-navigation--592eb";
export var hiddenTitle = "SliderImages-module--hidden-title--92e0f";
export var imgContainer = "SliderImages-module--img-container--ebaee";
export var mobile_image = "SliderImages-module--mobile_image--53154";
export var sectionContainer = "SliderImages-module--section-container--30416";
export var sliderImg = "SliderImages-module--slider-img--e8d9a";
export var swiperContainer = "SliderImages-module--swiper-container--41140";