import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import * as styles from "./hero.module.scss"
import { PlayIcon } from "../../../../svgs/play"
import Banner from "../../Banner/Banner"
import YoutubeVideo from "../../YoutubeVideo"
import { Link } from "gatsby"
import { useLocalPath } from "../../../../hooks"

import { useWindowSize } from 'react-use'
import { useLocale } from "../../../../hooks"
import Helmet from 'react-helmet'

const Hero = ({ heroSectionData, showVideo = true, index }) => {
    const [playVideo, setPlayVideo] = useState(false)
    const { width: windowWidth } = useWindowSize()
    const [interaction, setInteraction] = useState(false)
    const events = ['scroll', 'mousemove', 'touchstart'];
    const initScripts = () => {
        setInteraction(true)

        events.forEach(event => {
            window.removeEventListener(event, initScripts)
        })
    }
    if (typeof window !== 'undefined') {
        events.forEach(event => {
            window.addEventListener(event, initScripts)
        })
    }
    const locale = useLocale()
    return (
        <>
        {heroSectionData.backgroundImageMobile &&
		<Helmet>
		<link rel="preload" as="image" media="(min-width: 400.1px) and (max-width: 767px)" href={`${heroSectionData.backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`} />
        <link rel="preload" as="image" media="(max-width: 400px)" href={`${heroSectionData.backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`} />
		</Helmet>
        }
            {
                (locale !== "tr-TR") ? <div>
                    <div className={styles.hero_section_container}>
                        {heroSectionData.backgroundImageMobile &&
                            // <Img
                            //     fluid={heroSectionData.backgroundImageMobile.fluid}
                            //     className={`${styles.hero_section_img} ${styles.mobileImage}`}
                            //     alt={heroSectionData?.backgroundImageMobile?.title ? heroSectionData?.backgroundImageMobile?.title : "Hero Image"}
                            //     loading={index === 0 ? "eager" : "lazy"}
                            //     fadeIn={false}
                            // />
                            <div className={`${styles.hero_section_img} ${styles.mobileImage}`}>
                            <div aria-hidden="true" style={{width: '100%', paddingBottom: '142.364%'}}></div>
                            <picture>
                                <source media="(min-width: 400.1px) and (max-width: 767px)" srcSet={`${heroSectionData.backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`} />
                                <source media="(max-width: 400px)" srcSet={`${heroSectionData.backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`} />
                                <img fetchpriority="high" src={`${heroSectionData.backgroundImageMobile.fluid.src}`}
                                loading={index === 0 ? "eager" : "lazy"}
                                alt={heroSectionData?.backgroundImageMobile?.title ? heroSectionData?.backgroundImageMobile?.title : "Hero Image"}                               
                                />
                            </picture>
                            </div>
                        }
                        {heroSectionData.backgroundImage &&
                            <Img
                                fluid={heroSectionData.backgroundImage.fluid}
                                className={`${styles.hero_section_img} ${styles.desktopImage}`}
                                alt={heroSectionData?.backgroundImage?.title ? heroSectionData?.backgroundImage?.title : "Hero Image"}
                                loading={index === 0 ? "eager" : "lazy"}
                                fadeIn={false}
                            />
                        }
                        <div className={styles.hero_section_title_wrapper}>
                            <h1>{heroSectionData.title}</h1>
                        </div>
                        {
                            (locale !== "en-CA") && (locale !== "fr-CA") && (locale !== "en-US") && (locale !== "es-US") && (locale !== "tr-TR") && showVideo
                                ? <div
                                    onClick={() => {
                                        setPlayVideo(true)
                                    }}
                                >
                                    <PlayIcon className={styles.hero_play_btn} />
                                </div>
                                : null
                        }
                        {
                            playVideo && showVideo && (heroSectionData?.backgroundVideo?.file?.url || heroSectionData.youtubeBackgroundVideo) &&
                            <YoutubeVideo
                                width="100%"
                                height="100%"
                                className={`${styles.hero_section_video} ${playVideo ? styles.show : ""
                                    }`}
                                videoSrcURL={heroSectionData.backgroundVideo
                                    ? heroSectionData.backgroundVideo.file.url
                                    : heroSectionData && heroSectionData.youtubeBackgroundVideo}
                                playVideo={playVideo}
                            ></YoutubeVideo>
                        }
                        {/* {heroSectionData.cta[0].slug && <Link to={useLocalPath(`/${heroSectionData.cta[0].slug}`)} className={styles.hero_play_btn}><button>{heroSectionData.cta[0].title}</button></Link>} */}
                        {heroSectionData.cta[0].slug && <Link target="_blank" to={`${heroSectionData.cta[0].slug}`} className={`${styles.heroCta} btn-primary`}>{heroSectionData.cta[0].title}</Link>}
                    </div>
                    {/* <Banner heroTitle={heroSectionData.title} color="gold"></Banner> */}
                </div>

                    :

                    <div>
                        <Link to={useLocalPath(`/${heroSectionData.cta[0].slug}`)}>
                            <div className={styles.hero_section_container}>
                                {heroSectionData.backgroundImageMobile &&
                                    <Img
                                        fluid={heroSectionData.backgroundImageMobile.fluid}
                                        className={`${styles.hero_section_img} ${styles.mobileImage}`}
                                        alt={heroSectionData?.backgroundImageMobile?.title ? heroSectionData?.backgroundImageMobile?.title : "Hero Image"}
                                        loading={index === 0 ? "eager" : "lazy"}
                                        fadeIn={false}
                                    />
                                }
                                {heroSectionData.backgroundImage &&
                                    <Img
                                        fluid={heroSectionData.backgroundImage.fluid}
                                        className={`${styles.hero_section_img} ${styles.desktopImage}`}
                                        alt={heroSectionData?.backgroundImage?.title ? heroSectionData?.backgroundImage?.title : "Hero Image"}
                                        loading={index === 0 ? "eager" : "lazy"}
                                        fadeIn={false}
                                    />
                                }
                                <div className={styles.hero_section_title_wrapper}>
                                    <h1>{heroSectionData.title}</h1>
                                </div>
                            </div>
                        </Link>
                    </div>}
        </>
    )
}

export default Hero
