import React, { useEffect, useRef } from "react";
import * as styles from "./SliderImages.module.scss";
import { useLocale } from "../../../hooks"

import Img from "gatsby-image";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Mousewheel, Keyboard } from "swiper";

const SliderImages = ({ data }) => {
  const { type } = data;
  const locale = useLocale()

  const autoplayDelay = locale === "tr-TR" ? 3000 : 5000;

  return (
    <>
      {type === "Homeslider" && <BannerSlider data={data} autoplayDelay={autoplayDelay} />}
      {type === "Articleslider" && <ArticleSlider data={data} />}
    </>
  );
};

const BannerSlider = ({ data,autoplayDelay }) => {
  const { navigation, sliderimages, sliderImagesmobile, bannerslug, bannertitle, heading } = data;
  const swiperRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideNext(); // Move to the next slide after 5 seconds
      }
    }, autoplayDelay);
    return () => clearTimeout(timer);
  }, [autoplayDelay]);

  const option = {
    spaceBetween: 20,
    loop: sliderimages.length > 2,
    slidesPerGroupSkip: 1,
    grabCursor: true,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    pagination: {
      clickable: true,
      navigation: navigation,
    },
    autoplay: {
      delay: autoplayDelay, 
      disableOnInteraction: false, 
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: false,
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 5,
        navigation: false,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    modules: [Pagination, Mousewheel, Keyboard, Autoplay],
    slidesPerView: 3,
  };

  return (
    <div>
      <Swiper {...option} ref={swiperRef} className="Banner_Swipper">
        {heading && <h1 className={styles.hiddenTitle}>{heading}</h1>}
        {sliderimages.map((sliderimage, index) => {
          const showCaption = index === 1; // Show caption only on the second slide
          const mobileImage = sliderImagesmobile[index]?.fluid || sliderimage.fluid;
          const herocta = showCaption && (
            <div className={styles.Herocta}>
              {bannerslug && <a
                href={bannerslug}
                target="_blank"
                className={`${styles.bannercta} btn-primary`}
                rel="noopener noreferrer"
              >
                {bannertitle}
              </a>}
            </div>
          );

          return (
            <SwiperSlide key={index}>
              <div className={styles.desktop_image}>
                <Img
                  fluid={sliderimage.fluid}
                  alt={`Hero Image ${index + 1}`}
                  loading="lazy"
                  fadeIn={false}
                />
                {showCaption && herocta}
              </div>
              <div className={styles.mobile_image}>
                <Img
                  fluid={mobileImage}
                  alt={`Hero Image ${index + 1}`}
                  loading="lazy"
                  fadeIn={false}
                />
                {showCaption && herocta}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};





const ArticleSlider = ({ data }) => {
  const { navigation, dynamicBullets, sliderimages } = data;
  const swiperRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.update();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const option = {
    spaceBetween: 20,
    loop: sliderimages.length > 2,
    slidesPerGroupSkip: 1,
    grabCursor: true,
    keyboard: {
      enabled: true,
      navigation: navigation,
    },
    pagination: {
      clickable: true,
      navigation: navigation,
    },
    autoplay: {
      delay: 3000,
      navigation: navigation,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: false,
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 5,
        navigation: false,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    modules: [Pagination, Autoplay, Mousewheel, Keyboard],
    slidesPerView: 3,
  };

  return (
    <div className={styles.imgContainer}>
      <Swiper {...option} ref={swiperRef}>
        {sliderimages.map((sliderimage, index) => (
          <SwiperSlide key={index}>
            <div className={styles.sliderImg}>
              <Img
                fluid={sliderimage.fluid}
                alt={"Hero Image"}
                loading="lazy"
                fadeIn={false}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};


export default SliderImages;
