import React from "react"
import * as styles from "./section-alternateSE-grid.module.scss"
import { RichText } from "../../components/Layout/RichText/RichText"
import Img from "gatsby-image"

const SectionAlternateGridSE = ({
  position,
  sectionContent,
  description,
  sectionImage,
  featuredImg,
  featuredImgMobile,
  title,
  subtitle,
  backgroundColor,
  buttonText,
  buttonTextLink,
  cta,
  type,
}: {
  position?: String
  sectionContent: any
  description: any
  sectionImage: any
  featuredImg: any
  featuredImgMobile : any
  title?: any
  subtitle?: any
  backgroundColor: any
  buttonText: any
  buttonTextLink: any
  cta: any
  type: String
}) => {
  const mystyle = {
    color: "#ffdf0b",
    fontFamily: "Arial",
    fontSize: "1rem",
    lineHeight: "0px",
  }


  const category = type.toLocaleLowerCase()
  const extraClass = position ? position.toLocaleLowerCase() : "left"
  if (category === "default") {
    return (
      <div
        className={styles.articles}
        style={{ backgroundColor: backgroundColor }}
      >
        {backgroundColor && (
          <div className={styles.layoutSE}>{backgroundColor}</div>
        )}
        <div className={`${styles.articleContainer} ${styles[extraClass]}`}>
          <div className={styles.wrapper}>
            <div className={`styles.image`}>
              {sectionImage && (
                <Img fluid={sectionImage?.fluid} alt={sectionImage.title} />
              )}
              {/* This is used for home page featured Product Image Lipton US */}

              <div className={!featuredImgMobile ? styles.featuredImg : styles.featuredImgNew}>
                {featuredImg && (
                  <Img fluid={featuredImg?.fluid} alt={featuredImg.title} />
                )}
                {/* This is used for home page featured Product Image Lipton US */}
              </div>

              {featuredImgMobile && <div className={styles.featuredImgMobile}>
                {featuredImgMobile && (
                  <Img fluid={featuredImgMobile?.fluid} alt={featuredImgMobile.title} />
                )}
                {/* This is used for home page featured Product Image Lipton US */}
              </div>}
              
            </div>
          </div>
          <div className={styles.subtitleText}>
            <p style={mystyle}>{subtitle}</p>
            {title && <p className={styles.yellow_borderline}>{title}</p>}
            {sectionContent && <RichText content={sectionContent} />}

            {/* This is used for home page featured Product Image Lipton US */}
            <div className={styles.descriptionText}>
              {description && <RichText content={description} />}
            </div>
            {/* This is used for home page featured Product Image Lipton US */}
            {buttonText && (
              <div className={styles.buttonText}>
                <a target="_blank" href={buttonTextLink}>
                  {buttonText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else if (category === "Purpose") {
    const sectionTitle = title && title.split(",")
    return (
      <>
        {sectionTitle && (
          <div className={`${`styles.purposeTitle`} underline-heading`}>
            {sectionTitle.map(title => {
              return (
                <p>
                  <strong>{title}</strong>
                </p>
              )
            })}
          </div>
        )}
        <div className={`styles.articlesPurpose`}>
          <div className={`${styles.articleContainer} ${styles[extraClass]}`}>
            <div className={styles.wrapper}>
              <div className={`styles.image`}>
                <Img fluid={sectionImage.fluid} alt={sectionImage.title} />
              </div>
            </div>
            <div className={styles.wrapperText}>
              {sectionContent && <RichText content={sectionContent} />}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SectionAlternateGridSE
