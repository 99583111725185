// extracted by mini-css-extract-plugin
export var articleContainer = "section-alternateSE-grid-module--article-container--139df";
export var articles = "section-alternateSE-grid-module--articles--a71a4";
export var buttonText = "section-alternateSE-grid-module--button-text--0fc8a";
export var descriptionText = "section-alternateSE-grid-module--description-Text--98784";
export var featuredImg = "section-alternateSE-grid-module--featured-Img--1ac9a";
export var featuredImgMobile = "section-alternateSE-grid-module--featured-Img-Mobile--6dacb";
export var featuredImgNew = "section-alternateSE-grid-module--featured-Img-New--4abdc";
export var layoutSE = "section-alternateSE-grid-module--layout-SE--4bb15";
export var left = "section-alternateSE-grid-module--left--58e8b";
export var right = "section-alternateSE-grid-module--right--ec29a";
export var subtitleText = "section-alternateSE-grid-module--subtitle-text--0e874";
export var wrapper = "section-alternateSE-grid-module--wrapper--e962b";
export var wrapperText = "section-alternateSE-grid-module--wrapper-text--749b9";
export var yellow_borderline = "section-alternateSE-grid-module--yellow_borderline--a2900";